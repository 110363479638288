section.cta {
  @extend %container;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  padding: 1em 0;
  border: 2px solid #DEDEDE; // NOTE
  border-left: none;
  border-right: none;

  .text {
    font-size: 2.25em;
    margin-right: 1em;
    text-align: center;
  }

  button {
    font-size: 2em;
  }
}

@media (max-width: 420px) {
  section.cta {
    flex-direction: column;
    padding: 1.66em 0 2em;
  }
  section.cta .text {
    margin: 0 0 1em;
  }
}
