section.intro {
  @extend %container;

  @media (min-width: 560px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "main main nav";
    grid-column-gap: 2rem;
  }

  main {
    grid-area: main;
    margin-bottom: 3.3em;
  }

  header {
    margin: 0;
    font-size: 2em;
    padding-left: 2px;
    padding-top: 1em;
    h3 {
      margin: 0 0 .66em 0;
    }
  }

  article {
    font-size: .9em;
    padding-left: 2px;

    p {
      margin-top: 0;
    }

    @media (min-width: 560px) {
      padding-right: 2em;
    }
  }

  nav {
    font-size: .8em;
    padding: 1em 0 1em 2px;
    border-top: 2px solid #DEDEDE; // NOTE

    @media (min-width: 560px) {
      font-size: 1em;
      grid-area: nav;
      padding: 2em 1.5em;
      border: none;

      h3 {
        margin-bottom: .6em;
      }

      position: relative;
      &:before {
        display: block;
        content: '';

        height: 100%;
        width: 2px;
        background: #DEDEDE; // NOTE

        position: absolute;
        left: -1rem;
        top: 0;
      }
    }

    ul {
      margin: 0;
      li {
        list-style: none;
        margin: 0 0 .75em;
        &:last-child {
          margin: 0;
        }

        a {
          text-decoration: none;
          color: #A8A8A8; // NOTE
        }
      }
    }
  }
}
