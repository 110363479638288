a.author {
  font-size: 1.8em;
  text-decoration: none;

  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  .image {
    height: 2em;
    width: 2em;
    border-radius: 50%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .author {
    margin-left: 1ch;
    font-family: $DIN-M-400;
  }
}
