section.thumbnails {
  @extend %container;

  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;

  @media (min-width: 380px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
  }

  @media (min-width: 650px) {
    grid-template-columns: repeat(3, 1fr);
  }


  > h3 {
    grid-column: 1 / -1;
    margin: 0;
    padding: 2px 2px 0;
    border-top: 2px solid #DEDEDE; // NOTE
  }

  > button {
    font-size: 2em;
    margin: 0 auto;

    @media (min-width: 380px) {
      grid-column: span 2;
    }
    @media (min-width: 650px) {
      grid-column: 2 / span 1;
    }
  }

  article {
    font-size: 1em;

    a.thumbnail {
      position: relative;

      .img {
        padding-top: calc(((100 / 3) * 2) * 1%);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .tag {
        position: absolute;
        bottom: 1em;
        left: 1em;
        padding: .3em;

        @include font-mono-400;
        font-size: 1.2em;
        letter-spacing: .12em;
        color: #413f41; // NOTE
        text-transform: uppercase;
        white-space: nowrap;

        background: #DEDDDB; // NOTE
        border-radius: .25em;
      }
    }

    a.thumbnail ~ * {
      padding-left: 2px;
    }

    > h3 {
      font-size: 1em;
      margin: .75em 0 0;
    }

    p {
      font-size: 1em;
      margin: 0 0 1em;
    }

  }


}
