@mixin input-reset {
  appearance: none;
  margin: 0;
  border: none;
  background: inherit;
  &:focus {
    outline: none;
  }
  &[type="number"] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
    }
  }
}

@mixin invisible-input {
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

@mixin button-reset {
  appearance: none;
  box-sizing: content-box;
  background: none;
  border: none;
  border-radius: 0;
  text-decoration: none;
  font-family: inherit;
  margin: 0;
  line-height: 1em;
}
