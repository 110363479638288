section.search {
  @extend %container;

  input, .tag {
    @include font-400;
    margin: 1rem 1rem 0 0!important;
  }

  input {
    @include input-reset;
    font-size: 2em;

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-moz-placeholder,
    &:-moz-placeholder {
      color: #B6B6B6; // NOTE
    }

    width: auto;
    flex-grow: 1;
    width: 80px;
  }

  .tag {
    flex-shrink: 0;

    font-size: 1.25em;
    padding: .5em;
    border-radius: .2em;

    display: flex;
    align-items: center;

    box-sizing: border-box;
    max-width: calc(100% - .5rem);

    .icon {
      height: .75em;
      margin-left: 1em;
      flex-shrink: 0;
      cursor: pointer;
    }
    .text {
      line-height: 1;
      font-size: 1.2em;
      color: gray; // NOTE
    }
  }

  .searchbox {
    display: flex;
    flex-wrap: wrap;
    min-height: 52.5px;
    box-sizing: border-box;

    background-color: #F4F4F4; //NOTE
    padding: 0 1rem 1rem 1.66rem;
    border-radius: .5em;

    .tag {
      background-color: black;
      .icon svg path {
        fill: white; // NOTE
      }
      .text {
        color: white; // NOTE
      }
    }
  }

  .tags {
    display: flex;
    padding-left: 3px;

    .tag {
      cursor: pointer;
      .icon {
        display: none;
      }
      &:first-child {
        padding-left: 0;
      }
    }
  }
}
