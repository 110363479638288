@import "variables";
@import "mixins";
@import "fonts";

%container {
  max-width: 1024px;
  padding: 0 1rem;
  margin: 0 auto;

  @media (min-width: 900px) {
    padding: 0 2rem;
  }
}

.grid__container {
  background-color: white;
  padding: 0 0 5rem;
  margin-bottom: inherit;

  > section:last-child {
    margin-bottom: 0 !important;
  }

  border: 5px solid black;
  border-left: none;
  border-right: none;
}

// IMPORT GRIDS
@import "grids/thumbnails";
@import "grids/intro";
@import "grids/cta";
@import "grids/search";
@import "grids/header";
@import "grids/title";
@import "grids/article__header";
@import "grids/author";
