section.header {
  @extend %container;

  display: flex;
  align-items: center;

  position: relative;
  &:after {
    display: block;
    content: '';

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    height: 1px;
    width: 100vw;
    background-color: #DEDEDE; // NOTE
  }

  img {
    flex: 0;
    height: 3.6rem;
    margin: 1.66rem 0 1.88rem 0;
  }

  nav {
    display: flex;
    margin-left: auto; // align right

    ul {
      display: flex;
      margin: 0;
      padding: 0 1.5rem;

      &:not(:first-child) {
        border-left: 2px solid #DEDEDE; // NOTE
      }

      li {
        list-style: none;
        margin: 0;
        margin-right: 1.5rem;
        &:last-child {
          margin-right: 0;
        }

        a {
          text-decoration: none;
        }
        &.active {
          @include font-700;
        }
      }
    }
  }

  nav {
    display: none;
  }

  svg.icon {
    fill: black; // NOTE
    height: 2em;
    margin-left: auto; // align right
  }

  @media (min-width: 600px) {
    svg.icon {
      display: none;
    }
    nav {
      display: flex;
    }
  }
}
