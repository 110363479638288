section.title {
  padding-top: 10rem;
  padding-bottom: 5rem;

  a.tag {
    @include font-mono-400;
    font-size: 1.5em;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .125em;

    padding: .5em;
    border: 1px solid currentcolor;
  }

  h1 {
    @include font-cond-300;
    max-width: 500px; // NOTE
    margin: .66em 0 .5em 0;
    letter-spacing: -1.5;
  }

  p {
    max-width: 600px; // NOTE
    margin: 0;
  }


}
