header.article__header {
  margin: 0;
  margin-bottom: 4.5em;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  section.header,
  section.title {
    color: white; // NOTE
    margin-bottom: 0!important;
  }
  section.header {
    &:after {
      display: none;
    }
    svg.icon {
      fill: white;
    }
  }
}
